import RoomSuite from '@/assets/rooms/suite/room1.jpg';
import RoomSimple from '@/assets/rooms/simple/room1.jpg';
import RoomDoble from '@/assets/rooms/doble/room1.jpg';
// Suite
import suite1 from '@/assets/rooms/suite/room1.jpg';
import suite2 from '@/assets/rooms/suite/room2.jpg';
import suite3 from '@/assets/rooms/suite/room3.jpg';
import suite4 from '@/assets/rooms/suite/room4.jpg';
import suite5 from '@/assets/rooms/suite/room5.jpg';
// doble
import doble1 from '@/assets/rooms/doble/room1.jpg';
import doble2 from '@/assets/rooms/doble/room2.jpg';
import doble3 from '@/assets/rooms/doble/room3.jpg';
import doble4 from '@/assets/rooms/doble/room4.jpg';
import doble5 from '@/assets/rooms/doble/room5.jpg';
// simple
import simple1 from '@/assets/rooms/simple/room1.jpg';
import simple2 from '@/assets/rooms/simple/room2.jpg';
import simple3 from '@/assets/rooms/simple/room3.jpg';
import simple4 from '@/assets/rooms/simple/room4.jpg';
import simple5 from '@/assets/rooms/simple/room5.jpg';

// FOTOS: 30 / 26 / 34 / 2316
// 1970 / 1_1 / 8
// 2174 / 2177 / 24
const rooms = [
  {
    id: 1,
    name: 'suite',
    description: 'AMPLIA HABITACIÓN DE 44 M2 CON CAMA KING Y SALA DE ESTAR. PERFECTO PARA DISFRUTAR DE UNA EXPERIENCIA INIGUALABLE.',
    text1: 'SMART TV 49" EN MUEBLE GIRATORIO CON TV POR CABLE. INTERNET INALAMBRICO DE ALTA VELOCIDAD. TELÉFONO. ESCRITORIO. AMPLIOS ARMARIOS. CORTINAS OPACAS Y VENTANAS ANTIRUIDO. MINIBAR.',
    text2: 'CUARTOS DE BAÑO EQUIPADO CON SECADOR DE CABELLO , ESPEJO DE AUMENTO, BATAS, AMENITIES. BAÑERA CON HIDROMASAJE.',
    mainImage: RoomSuite,
    amenities: [
      {
        icon: 'fa-solid fa-bed',
        description: 'Cama King Size'
      },
      {
        icon: 'fa-solid fa-door-closed',
        description: 'Armario'
      },
      {
        icon: 'fa-solid fa-tty',
        description: 'Teléfono Interno'
      },
      {
        icon: 'fa-solid fa-satellite-dish',
        description: 'Tv Satelital'
      },
      {
        icon: 'fa-solid fa-chair',
        description: 'Escritorio'
      },
      {
        icon: 'fa-solid fa-vault',
        description: 'Caja Fuerte Electronica'
      },
      {
        icon: 'fa-solid fa-wine-glass',
        description: 'MiniBar'
      },
      {
        icon: 'fa-solid fa-bath',
        description: 'Tina Hidromasaje'
      },
      {
        icon: 'fa-solid fa-star',
        description: 'Amenities'
      },
      {
        icon: 'box',
        description: 'Batas'
      },
      {
        icon: 'desk',
        description: 'Secador de cabello'
      },
    ],
    images: [suite1, suite2, suite3, suite4, suite5],
  },
  {
    id: 2,
    name: 'doble Twin',
    description: 'ACOGEDORA HABITACION DE  29M2 CON 2 CAMAS FULL DISPONIBLES PARA OFRECE UN PLACEROSO DESCANSO.',
    text1: 'SMART TV 43" CON TV POR CABLE. INTERNET INALAMBRICO DE ALTA VELOCIDAD. ESCRITORIO. AMPLIOS ARMARIOS.  STORES Y VENTANAS ANTIRUIDO. MINIBAR.',
    text2: 'CUARTOS DE BAÑO EQUIPADO CON SECADOR DE CABELLO , ESPEJO DE AUMENTO, BATAS, AMENITIES. DUCHA. ',
    mainImage: RoomDoble,
    amenities: [
      {
        icon: 'fa-solid fa-bed',
        description: '2 camas de 2 plazas'
      },
      {
        icon: 'fa-solid fa-door-closed',
        description: 'Armario'
      },
      {
        icon: 'fa-solid fa-tty',
        description: 'Telefono Interno'
      },
      {
        icon: 'fa-solid fa-satellite-dish',
        description: 'TV Satelital'
      },
      {
        icon: 'fa-solid fa-chair',
        description: 'Escritorio'
      },
      {
        icon: 'fa-solid fa-vault',
        description: 'Caja Fuerte Electronica'
      },
      {
        icon: 'fa-solid fa-wine-glass',
        description: 'Minibar'
      },
      {
        icon: 'fa-solid fa-bath',
        description: 'Ducha Española'
      },
      {
        icon: 'fa-solid fa-star',
        description: 'Amenities'
      },
    ],
    images: [doble1,doble2,doble3, doble4, doble5],
  },
  {
    id: 3,
    name: 'Single - Matrimonial',
    description: 'MODERNA HABITACION DE  19M2 CON  CAMAS QUEEN TE INVIDA A DISFRUTAR DE UNA ESTADIA INOLVIDABLE.',
    text1: 'SMART TV 32" CON TV POR CABLE. INTERNET INALAMBRICO DE ALTA VELOCIDAD. ESCRITORIO. AMPLIOS ARMARIOS. STORES Y VENTANAS ANTIRUIDO. MINIBAR.',
    text2: 'CUARTOS DE BAÑO EQUIPADO CON SECADOR DE CABELLO , ESPEJO DE AUMENTO, BATAS, AMENITIES. DUCHA.',
    mainImage: RoomSimple,
    amenities: [
      {
        icon: 'fa-solid fa-bed',
        description: 'Cama Queen Size'
      },
      {
        icon: 'fa-solid fa-door-closed',
        description: 'Armario'
      },
      {
        icon: 'fa-solid fa-tty',
        description: 'Teléfono Interno'
      },
      {
        icon: 'fa-solid fa-satellite-dish',
        description: 'TV Satelital'
      },
      {
        icon: 'fa-solid fa-chair',
        description: 'Escritorio'
      },
      {
        icon: 'fa-solid fa-vault',
        description: 'Caja Fuerte Electronica'
      },
      {
        icon: 'fa-solid fa-wine-glass',
        description: 'Minibar'
      },
      {
        icon: 'fa-solid fa-bath',
        description: 'Ducha Española'
      },
      {
        icon: 'fa-solid fa-star',
        description: 'Amenities'
      },
    ],
    images: [simple1, simple2, simple3, simple4, simple5],
  },
];

export default rooms;