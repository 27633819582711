<template>
  <div class="room-item">
    <div class="room-item--wrapper">
      <div class="room-item--image">
        <div
          class="room-item--bg"
          :style="`background-image: url(${room.mainImage})`"
        >
          <img :src="room.mainImage" :alt="`Hotel Equo Habitación ${room.name}`">
        </div>
      </div>
      <div class="room-item--content">
        <div class="room-item--description">
          <h4>{{ room.name }}</h4>
          <p>{{ room.text1 }}</p>
          <p>{{ room.text2 }}</p>
          <div class="clearfix">
            <router-link class="btn-link" :to="{ name: 'RoomDetailPage', params: { room: room.name } }">VER MÁS</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RoomItem',
  props: {
    room: {
      type: Object,
      default: () => {}
    }
  }
}
</script>