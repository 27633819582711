<template>
  <div class="room-page">
    <div class="container">
      <div class="section-banner">
        <div class="col-title">
          <div class="block-bg">
            <h4>HABITACIONES</h4>
          </div>
        </div>
        <div class="col-image">
          <img src="@/assets/rooms/suite/room3.jpg" />
        </div>
      </div>
      <div class="section-list">
        <div class="room">
          <room-item v-for="item in roomList" :key="`room_${item.id}`" :room="item"></room-item>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import roomList from '@/utils/rooms'
import RoomItem from '@/components/RoomItem'

export default {
  name: 'RoomPage',
  components: { RoomItem },
  data: () => ({
    roomList,
    loading: false,
  })
}
</script>
